<template>
  <div class="device-wrapper">
    <div class="device">
      <div class="device-counter">{{ device.connection_number }}</div>
      <header>
        <div>{{ deviceState.type }} {{ deviceState.id }}</div>
        <div>
          <span>{{ deviceState.last_packet_time | getShortTime }}</span>
          <sup class="ml-1">{{ deviceState.last_packet_time | getShortDate }}</sup>
        </div>
      </header>
      <main class="text-sm-center">
        <div></div>
        <div class="am-tank-water">
          <AmSVG :fillColor="fillColor" :height="AmSwgHeight" />
          <span v-if="deviceSpecState.tank_water">{{ deviceSpecState.tank_water }} л</span>
        </div>
        <div class="text-truncate">
          <img v-if="deviceState.is_blocked" :src="require('@/assets/images/lock.svg')" height="13" alt="lock" />
          <HelpTooltip :text="deviceState.state_description" bottom>
            <span class="d-block text-truncate">{{ deviceState.state }}</span>
          </HelpTooltip>
        </div>
      </main>
      <footer class="footer">
        <div class="d-flex align-center">
          <img :src="require('@/assets/images/tds.svg')" height="13" alt="tds" />
          <span :style="{ color: tds2Color }" class="ml-1">{{ deviceSpecState.sensors.tds2 }}</span>
        </div>
        <div>{{ deviceState.balance }} ₴</div>
      </footer>
    </div>
  </div>
</template>

<script>
import convertDate from '@/mixins/convertDate'

export default {
  name: 'OnlineDeviceAM',

  components: {
    AmSVG: () => import('@/components/icons/AmSVG'),
    HelpTooltip: () => import('@/components/common/HelpTooltip'),
  },

  mixins: [convertDate],

  props: {
    terminal: {
      type: Object,
      required: true,
      default: () => {},
    },
    device: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data: () => ({
    AmSwgHeight: 48,
  }),

  computed: {
    deviceState() {
      return this.terminal.state?.devices.find((item) => item.id === this.device.id) || this.device.state
    },
    deviceSpecState() {
      return this.deviceState.device_spec_state
    },
    fillColor() {
      if (this.deviceState?.is_online) {
        return this.deviceState.state_color || 'grey'
      } else {
        return 'grey'
      }
    },
    tds2Color() {
      return this.deviceState.is_online ? (this.deviceSpecState.sensors.tds2 > 50 ? 'red' : 'green') : 'inherit'
    },
  },

  created() {},

  beforeDestroy() {},

  methods: {},
}
</script>

<style scoped lang="scss">
.device {
  &-wrapper {
    display: flex;
    justify-content: center;
  }
  &-counter {
    position: absolute;
    z-index: 0;
    right: 0;
    top: 56px;
    font-size: 65px;
    line-height: 1;
    color: rgba(20, 210, 251, 1);
    opacity: 10%;
  }
  position: relative;
  width: 160px;
  height: 170px;
  border-radius: 8px;
  box-shadow: 0 1px 3px 1px rgba(0, 41, 143, 0.15);
  padding: 0 8px;
}
header {
  display: flex;
  justify-content: space-between;
  div:first-child {
    font-size: 10px;
    font-weight: 700;
    line-height: 28px;
  }
  div:last-child {
    height: 28px;
    border-radius: 4px;
    padding: 8px 4px;
    background-color: rgba(246, 245, 245, 1);
    font-size: 10px;
    color: rgba(0, 132, 61, 1);
  }
}
main {
  margin-top: 15px;
  & > div:first-child {
    font-size: 14px;
    height: 21px;
  }
  & > div:last-child {
    font-size: 12px;
  }
}
.footer {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  margin-top: 12px;
}
.am-tank-water {
  position: relative;
  span {
    position: absolute;
    top: 24px;
    right: 90px;
    font-size: 10px;
  }
}
</style>
